import {useState} from 'react';
import {
  Box,
  Link,
  Tooltip,
  useTheme,
  Collapse,
  TableRow,
  TextField,
  TableCell,
  IconButton,
  Typography,
} from '@material-ui/core';
import {
  Apps,
  FileCopy,
  KeyboardArrowUp,
  KeyboardArrowDown,
} from '@material-ui/icons';

import {
  IUrlComposition,
  IUrlResponse,
} from 'shared/services/api/ExternalUrlService';

interface IProps {
  item: IUrlResponse;
  dataRequest: IUrlComposition;
  copyToClipboard: (uri: string) => void;
  handleOpenQRCode: (uri: string) => void;
}

export const AdvancedUrl: React.FC<IProps> = ({
  item,
  dataRequest,
  copyToClipboard,
  handleOpenQRCode,
}) => {
  const [open, setOpen] = useState(false);

  const theme = useTheme();

  return (
    <>
      <TableRow>
        <TableCell>
          <Box display="flex" gridGap={4}>
            <Tooltip title={`Copiar URL`}>
              <IconButton onClick={() => copyToClipboard(item.uri)}>
                <FileCopy />
              </IconButton>
            </Tooltip>
            <Tooltip title={`Gerar QR Code`}>
              <IconButton onClick={() => handleOpenQRCode(item.uri)}>
                <Apps />
              </IconButton>
            </Tooltip>
          </Box>
        </TableCell>
        {dataRequest.idComposicaoUri !== 1 && (
          <TableCell>{item.estabelecimento}</TableCell>
        )}
        {dataRequest.idComposicaoUri === 3 && (
          <TableCell>{item.curso}</TableCell>
        )}
        <TableCell>
          <Tooltip title={`Copiar URL`}>
            <Link
              color="primary"
              href="#"
              onClick={(e) => {
                e.preventDefault();
                copyToClipboard(item.uri);
              }}>
              {item.uri}
            </Link>
          </Tooltip>
        </TableCell>
        <TableCell width={100} align="center">
          <IconButton
            size="small"
            color="primary"
            onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell
          padding="none"
          style={{borderBottom: 0}}
          colSpan={
            dataRequest.idComposicaoUri === 1
              ? 3
              : dataRequest.idComposicaoUri === 2
              ? 4
              : 5
          }>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box px={3} py={2}>
              <Typography gutterBottom>
                Colar a URL da página a ser promovida.
              </Typography>
              <Box mb={3} display="flex">
                <TextField
                  fullWidth
                  size="small"
                  color="primary"
                  variant="outlined"
                  label="Link da URL"
                  value={item.uri}
                  inputProps={{style: {color: theme.palette.primary.main}}}
                />
                <Box mx={2} display="flex" alignItems="center">
                  <IconButton onClick={() => copyToClipboard(item.uri)}>
                    <FileCopy />
                  </IconButton>
                </Box>
              </Box>

              <Typography gutterBottom>
                Informe a origem do tráfego (ex: site ou rede social).
              </Typography>
              <TextField
                fullWidth
                size="small"
                color="primary"
                variant="outlined"
                label="Fonte da campanha*"
              />
              <Box mb={3} />

              <Typography gutterBottom>
                Diga como você está divulgando a campanha (ex: e-mail, cpc,
                banner).
              </Typography>
              <TextField
                fullWidth
                size="small"
                color="primary"
                variant="outlined"
                label="Meio de marketing*"
              />
              <Box mb={3} />

              <Typography gutterBottom>
                Dê um nome descritivo à sua campanha.
              </Typography>
              <TextField
                fullWidth
                size="small"
                color="primary"
                variant="outlined"
                label="Nome da campanha*"
              />
              <Box mb={3} />

              <Typography gutterBottom>
                Use um código único para rastreamento, no provedor de anúncios.
              </Typography>
              <TextField
                fullWidth
                size="small"
                color="primary"
                variant="outlined"
                label="Identificador da campanha"
              />
              <Box mb={3} />

              <Typography gutterBottom>
                Adicione palavras-chave pagas para anúncios.
              </Typography>
              <TextField
                fullWidth
                size="small"
                color="primary"
                variant="outlined"
                label="Termo da campanha"
              />
              <Box mb={3} />

              <Typography gutterBottom>
                Utilize para diferenciar conteúdo similar ou links dentro do
                mesmo anúncio.
              </Typography>
              <TextField
                fullWidth
                size="small"
                color="primary"
                variant="outlined"
                label="Conteúdo da campanha"
              />
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};
